import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
// import { useState, useEffect } from 'react';
import React, { useState, useEffect, Suspense } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { baseURL } from './components/pages/backend_url';
import axios from 'axios';



import Navibar from './components/layout/Navibar';
import NavibarCollasped from './components/layout/NavibarCollasped';
import Sidebar from './components/layout/Sidebar';
// import AccountSetup from './components/pages/account/Account'
// import UserSetup from './components/pages/user/User'
import Dashboard from './components/pages/dashboard/Dashboard'
// import Locations from './components/pages/locations/Locations'
// import AccountLocations from './components/pages/accountLocations/AccountLocations'
// import TaxSetup from './components/pages/taxSetup/TaxSetup'
// import FleetSetUp from "./components/pages/fleetSetUp/FleetSetUp"
// import AccountRateCodeSetup from "./components/pages/accountRateCodeSetup/AccountRateCodeSetup"
// import RateCodeSetup from "./components/pages/rateCodeSetup/RateCodeSetup"
// import InstantAutomationPro from './components/pages/instantAutomationPro/InstantAutomationPro';
// import InstantRateShop from './components/pages/instantRateShop/instantRateShop';
// import AutomationPlans from './components/pages/automationPlans/AutomationPlans';
import Login from "./components/pages/login/Login";
// import Website from "./components/pages/websitePage/Website";
// import ContactUs from "./components/pages/contactUs/ContactUs";
// import RolesSetup from "./components/pages/rolesSetup/RolesSetup";
// import Cars from "./components/pages/cars/Cars";
// import Reports from "./components/pages/reports/Reports";
// import EventLog from './components/pages/eventLog/EventLog';
// import FleetStandard from './components/pages/fleetStandard/FleetStandard';
// import RateCodeMapping from './components/pages/rateCodeMapping/RateCodeMapping';
// // import RateSnapshot from './components/pages/rateSnapshot/RateSnapshot';
// // import OptionalServicesImages from './components/pages/optionalServicesImages/OptionalServicesImages';
// import MyProfile from './components/pages/my_profile/MyProfile';
// import CompetitorSetup from './components/pages/competitorSetup/CompetitorSetup';
// import MinimumRateMapping from './components/pages/minimumRateMapping/MinimumRateMapping';
// import EnableAutomation from './components/pages/enableAutomation/EnableAutomation';
// import AutomationScheduler from './components/pages/automationSetUp/AutomationSetUp';

// import CurrencyConverter from './components/pages/currencyConverter/CurrencyConverter';
// import ReportSetUp from './components/pages/reportSetUp/ReportSetUp';
// import RateDownload from './components/pages/rateDownload/RateDownload';
// import Competitors from './components/pages/competitors/Competitors';

// import MILocation from './components/pages/marketInsights/Location';
// import MIHotDates from './components/pages/marketInsights/HotDates';
// import CustomStrategy from './components/pages/strategy/Strategy';
import Website from "./components/pages/websitePage/Website";
import ContactUs from "./components/pages/contactUs/ContactUs";

// Lazy load all page components

// const Navibar = React.lazy(() => import('./components/layout/Navibar'));
// const NavibarCollasped = React.lazy(() => import('./components/layout/NavibarCollasped'));
// const Sidebar = React.lazy(() => import('./components/layout/Sidebar'));
// const AccountSetup = React.lazy(() => import('./components/pages/account/Account'));
// const UserSetup = React.lazy(() => import('./components/pages/user/User'));
// const Dashboard = React.lazy(() => import('./components/pages/dashboard/Dashboard'));
// const Locations = React.lazy(() => import('./components/pages/locations/Locations'));
// const AccountLocations = React.lazy(() => import('./components/pages/accountLocations/AccountLocations'));
// const TaxSetup = React.lazy(() => import('./components/pages/taxSetup/TaxSetup'));
// const FleetSetUp = React.lazy(() => import("./components/pages/fleetSetUp/FleetSetUp"));
// const AccountRateCodeSetup = React.lazy(() => import("./components/pages/accountRateCodeSetup/AccountRateCodeSetup"));
// const RateCodeSetup = React.lazy(() => import("./components/pages/rateCodeSetup/RateCodeSetup"));
// const InstantAutomationPro = React.lazy(() => import('./components/pages/instantAutomationPro/InstantAutomationPro'));
// const InstantRateShop = React.lazy(() => import('./components/pages/instantRateShop/instantRateShop'));
// const AutomationPlans = React.lazy(() => import('./components/pages/automationPlans/AutomationPlans'));
// const Login = React.lazy(() => import("./components/pages/login/Login"));
// const Website = React.lazy(() => import("./components/pages/websitePage/Website"));
// const ContactUs = React.lazy(() => import("./components/pages/contactUs/ContactUs"));
// const RolesSetup = React.lazy(() => import("./components/pages/rolesSetup/RolesSetup"));
// const Cars = React.lazy(() => import("./components/pages/cars/Cars"));
// const Reports = React.lazy(() => import("./components/pages/reports/Reports"));
// const EventLog = React.lazy(() => import('./components/pages/eventLog/EventLog'));
// const FleetStandard = React.lazy(() => import('./components/pages/fleetStandard/FleetStandard'));
// const RateCodeMapping = React.lazy(() => import('./components/pages/rateCodeMapping/RateCodeMapping'));
// const MyProfile = React.lazy(() => import('./components/pages/my_profile/MyProfile'));
// const CompetitorSetup = React.lazy(() => import('./components/pages/competitorSetup/CompetitorSetup'));
// const MinimumRateMapping = React.lazy(() => import('./components/pages/minimumRateMapping/MinimumRateMapping'));
// const EnableAutomation = React.lazy(() => import('./components/pages/enableAutomation/EnableAutomation'));
// const AutomationScheduler = React.lazy(() => import('./components/pages/automationSetUp/AutomationSetUp'));
// const CurrencyConverter = React.lazy(() => import('./components/pages/currencyConverter/CurrencyConverter'));
// const ReportSetUp = React.lazy(() => import('./components/pages/reportSetUp/ReportSetUp'));
// const RateDownload = React.lazy(() => import('./components/pages/rateDownload/RateDownload'));
// const Competitors = React.lazy(() => import('./components/pages/competitors/Competitors'));
// const MILocation = React.lazy(() => import('./components/pages/marketInsights/Location'));
// const MIHotDates = React.lazy(() => import('./components/pages/marketInsights/HotDates'));
// const CustomStrategy = React.lazy(() => import('./components/pages/strategy/Strategy'));

// const preloadComponents = () => {
//   import('./components/layout/Navibar');
//   import('./components/layout/NavibarCollasped');
//   import('./components/layout/Sidebar');
//   import('./components/pages/account/Account');
//   import('./components/pages/user/User');
//   import('./components/pages/dashboard/Dashboard');
//   import('./components/pages/locations/Locations');
//   import('./components/pages/accountLocations/AccountLocations');
//   import('./components/pages/taxSetup/TaxSetup');
//   import('./components/pages/fleetSetUp/FleetSetUp');
//   import('./components/pages/accountRateCodeSetup/AccountRateCodeSetup');
//   import('./components/pages/rateCodeSetup/RateCodeSetup');
//   import('./components/pages/instantAutomationPro/InstantAutomationPro');
//   import('./components/pages/instantRateShop/instantRateShop');
//   import('./components/pages/automationPlans/AutomationPlans');
//   import('./components/pages/login/Login');
//   import('./components/pages/websitePage/Website');
//   import('./components/pages/contactUs/ContactUs');
//   import('./components/pages/rolesSetup/RolesSetup');
//   import('./components/pages/cars/Cars');
//   import('./components/pages/reports/Reports');
//   import('./components/pages/eventLog/EventLog');
//   import('./components/pages/fleetStandard/FleetStandard');
//   import('./components/pages/rateCodeMapping/RateCodeMapping');
//   import('./components/pages/my_profile/MyProfile');
//   import('./components/pages/competitorSetup/CompetitorSetup');
//   import('./components/pages/minimumRateMapping/MinimumRateMapping');
//   import('./components/pages/enableAutomation/EnableAutomation');
//   import('./components/pages/automationSetUp/AutomationSetUp');
//   import('./components/pages/currencyConverter/CurrencyConverter');
//   import('./components/pages/reportSetUp/ReportSetUp');
//   import('./components/pages/rateDownload/RateDownload');
//   import('./components/pages/competitors/Competitors');
//   import('./components/pages/marketInsights/Location');
//   import('./components/pages/marketInsights/HotDates');
//   import('./components/pages/strategy/Strategy');
// };


function App() {
  const [collaspedFlag, setCollapsedFlag] = useState(false);
  const [sidebarColor, setSidebarColor] = useState("");
  const [loginSuccess, setLoginSuccess] = useState(false);
  const [moduleDetail, setModuleDetail] = useState([]);
  const [moduleDetailHash, setModuleDetailHash] = useState({});
  const [moduleDetailHash2, setModuleDetailHash2] = useState({});
  const [subModuleData, setSubModuleData] = useState([{}]);

  const [components, setComponents] = useState({});
  const [condition, setCondition] = useState(false); // Example condition

  



  useEffect(() => {
  if(window.location.href.split('/RateMatePro')[1] != ""){
    const preloadComponents = async () => {
      const loadedComponents = {
        Navibar: (await import('./components/layout/Navibar')).default,
        NavibarCollasped: (await import('./components/layout/NavibarCollasped')).default,
        Sidebar: (await import('./components/layout/Sidebar')).default,
        AccountSetup: (await import('./components/pages/account/Account')).default,
        UserSetup: (await import('./components/pages/user/User')).default,
        Dashboard: (await import('./components/pages/dashboard/Dashboard')).default,
        Locations: (await import('./components/pages/locations/Locations')).default,
        AccountLocations: (await import('./components/pages/accountLocations/AccountLocations')).default,
        TaxSetup: (await import('./components/pages/taxSetup/TaxSetup')).default,
        FleetSetUp: (await import('./components/pages/fleetSetUp/FleetSetUp')).default,
        AccountRateCodeSetup: (await import('./components/pages/accountRateCodeSetup/AccountRateCodeSetup')).default,
        RateCodeSetup: (await import('./components/pages/rateCodeSetup/RateCodeSetup')).default,
        InstantAutomationPro: (await import('./components/pages/instantAutomationPro/InstantAutomationPro')).default,
        InstantRateShop: (await import('./components/pages/instantRateShop/instantRateShop')).default,
        AutomationPlans: (await import('./components/pages/automationPlans/AutomationPlans')).default,
        Login: (await import('./components/pages/login/Login')).default,
        Website: (await import('./components/pages/websitePage/Website')).default,
        ContactUs: (await import('./components/pages/contactUs/ContactUs')).default,
        RolesSetup: (await import('./components/pages/rolesSetup/RolesSetup')).default,
        Cars: (await import('./components/pages/cars/Cars')).default,
        Reports: (await import('./components/pages/reports/Reports')).default,
        EventLog: (await import('./components/pages/eventLog/EventLog')).default,
        FleetStandard: (await import('./components/pages/fleetStandard/FleetStandard')).default,
        RateCodeMapping: (await import('./components/pages/rateCodeMapping/RateCodeMapping')).default,
        MyProfile: (await import('./components/pages/my_profile/MyProfile')).default,
        CompetitorSetup: (await import('./components/pages/competitorSetup/CompetitorSetup')).default,
        MinimumRateMapping: (await import('./components/pages/minimumRateMapping/MinimumRateMapping')).default,
        EnableAutomation: (await import('./components/pages/enableAutomation/EnableAutomation')).default,
        AutomationScheduler: (await import('./components/pages/automationSetUp/AutomationSetUp')).default,
        CurrencyConverter: (await import('./components/pages/currencyConverter/CurrencyConverter')).default,
        ReportSetUp: (await import('./components/pages/reportSetUp/ReportSetUp')).default,
        RateDownload: (await import('./components/pages/rateDownload/RateDownload')).default,
        Competitors: (await import('./components/pages/competitors/Competitors')).default,
        MILocation: (await import('./components/pages/marketInsights/Location')).default,
        MIHotDates: (await import('./components/pages/marketInsights/HotDates')).default,
        CustomStrategy: (await import('./components/pages/strategy/Strategy')).default,
      };
  
      setComponents(loadedComponents);
  };

    const loggedInUser = sessionStorage.getItem("user_token");
    const foundUser = JSON.parse(loggedInUser);
    axios.get(baseURL + "/get_modules")
      .then((res) => {
        // console.log('module res',res)
        const loggedInUser1 = sessionStorage.getItem("user_token");
        let temp_hash = {};
        let temp_hash2 = {};   
      if (loggedInUser1) {

          console.log('im 6666666666666666666')

          setLoginSuccess(true);
          preloadComponents();
        //   let temp_hash = {};
        // let temp_hash2 = {};      
        const temp_module_arr = res.data.map((val) => {
          let temp_mod_names = [];
          let subModuleExists = val.hasOwnProperty('sub_module');
          let sub_modules = subModuleExists ? val.sub_module : null;

          let temp_mod_name = val.name.toString().trim();
          if (temp_mod_name.indexOf(' ') > -1) {
              let t1_split = temp_mod_name.split(" ");
              if (t1_split.length > 0) {
                  temp_mod_name = '';
                  for (let i1 = 0; i1 < t1_split.length; i1++) {
                      temp_mod_name += t1_split[i1];
                  }
              }
          }
          if (sub_modules !== null && sub_modules.length > 0) {
            for (let i = 0; i < sub_modules.length; i++) {
              let sub_module = sub_modules[i].routing;
              if (sub_module.indexOf(' ') > -1) {
                let t1_split = sub_module.split(" ");
                if (t1_split.length > 0) {
                    sub_module = '';
                    for (let i1 = 0; i1 < t1_split.length; i1++) {
                      sub_module += t1_split[i1];
                    }
                }
              }
              temp_mod_names.push(sub_module.toString().trim());
            }
          }
          temp_mod_names.push(temp_mod_name);
          if (temp_mod_names.length > 1) {
            temp_mod_names.forEach((temp_mod_name, index) => {
              if (temp_mod_name === 'Min/MaxSettings') {
                temp_hash2[temp_mod_name] = components.MinimumRateMapping;
              } else if (temp_mod_name === 'fleet_setup') {
                temp_hash2[temp_mod_name] = components.FleetSetUp;
              } else if (temp_mod_name === 'fees_taxes') {
                temp_hash2[temp_mod_name] = components.TaxSetup;
              } else if (temp_mod_name === 'competitor_setup') {
                temp_hash2[temp_mod_name] = components.CompetitorSetup;
              } else if (temp_mod_name === 'Account') {
                temp_hash2[temp_mod_name] = components.AccountSetup;
              } else if (temp_mod_name === 'locations') {
                temp_hash2[temp_mod_name] = components.Locations;
              } else if (temp_mod_name === 'AccountLocations') {
                temp_hash2[temp_mod_name] = components.AccountLocations;
              } else if (temp_mod_name === 'competitors') {
                temp_hash2[temp_mod_name] = components.Competitors;
              } else if (temp_mod_name === 'fleet_standard') {
                temp_hash2[temp_mod_name] = components.FleetStandard;
              } else if (temp_mod_name === 'automation_plan') {
                temp_hash2[temp_mod_name] = components.AutomationPlans;
              } else if (temp_mod_name === 'user') {
                temp_hash2[temp_mod_name] = components.UserSetup;
              } else if (temp_mod_name === 'cars') {
                temp_hash2[temp_mod_name] = components.Cars;
              } else if (temp_mod_name === 'InstantAutomationPro') {
                temp_hash2[temp_mod_name] = components.InstantAutomationPro;
              } else if (temp_mod_name === 'InstantRateShop') {
                temp_hash2[temp_mod_name] = components.InstantRateShop;
              } else if (temp_mod_name === 'AutomationScheduler') {
                temp_hash2[temp_mod_name] = components.AutomationScheduler;
              } else if (temp_mod_name === 'report_setup') {
                temp_hash2[temp_mod_name] = components.ReportSetUp;
              } else if (temp_mod_name === 'rate_download') {
                temp_hash2[temp_mod_name] = components.RateDownload;
              }
            });
          } else {
              // temp_mod_names.forEach(temp_mod_name => { 
              temp_mod_names.forEach(async (temp_mod_name) => {  
                if (temp_mod_name === 'Account') {
                  const { default: Account } = await import('./components/pages/account/Account');
                  temp_hash[temp_mod_name] = <Account />;
                } else if (temp_mod_name === 'Min/MaxSettings') {
                  const { default: MinimumRateMapping } = await import('./components/pages/minimumRateMapping/MinimumRateMapping');
                  temp_hash[temp_mod_name] = <MinimumRateMapping />;
                } else if (temp_mod_name === 'FeesandTaxes') {
                  const { default: TaxSetup } = await import('./components/pages/taxSetup/TaxSetup');
                  temp_hash[temp_mod_name] = <TaxSetup />;
                } else if (temp_mod_name === 'CompetitorSetUp') {
                  const { default: CompetitorSetup } = await import('./components/pages/competitorSetup/CompetitorSetup');
                  temp_hash[temp_mod_name] = <CompetitorSetup />;
                } else if (temp_mod_name === 'AccountLocations') {
                  const { default: AccountLocations } = await import('./components/pages/accountLocations/AccountLocations');
                  temp_hash[temp_mod_name] = <AccountLocations />;
                } else if (temp_mod_name === 'Locations') {
                  const { default: Locations } = await import('./components/pages/locations/Locations');
                  temp_hash[temp_mod_name] = <Locations />;
                } else if (temp_mod_name === 'Competitors') {
                  const { default: Competitors } = await import('./components/pages/competitors/Competitors');
                  temp_hash[temp_mod_name] = <Competitors />;
                } else if (temp_mod_name === 'FleetStandard') {
                  const { default: FleetStandard } = await import('./components/pages/fleetStandard/FleetStandard');
                  temp_hash[temp_mod_name] = <FleetStandard />;
                } else if (temp_mod_name === 'FleetSetUp') {
                  const { default: FleetSetUp } = await import('./components/pages/fleetSetUp/FleetSetUp');
                  temp_hash[temp_mod_name] = <FleetSetUp />;
                } else if (temp_mod_name === 'AutomationPlan') {
                  const { default: AutomationPlans } = await import('./components/pages/automationPlans/AutomationPlans');
                  temp_hash[temp_mod_name] = <AutomationPlans />;
                } else if (temp_mod_name === 'User') {
                  const { default: UserSetup } = await import('./components/pages/user/User');
                  temp_hash[temp_mod_name] = <UserSetup />;
                } else if (temp_mod_name === 'InstantAutomationPro') { 
                  const { default: InstantAutomationPro } = await import('./components/pages/instantAutomationPro/InstantAutomationPro');
                  temp_hash[temp_mod_name] = <InstantAutomationPro />;
                } else if (temp_mod_name === 'InstantRateShop') {
                  const { default: InstantRateShop } = await import('./components/pages/instantRateShop/instantRateShop');
                  temp_hash[temp_mod_name] = <InstantRateShop />;
                } else if (temp_mod_name === 'AutomationScheduler') {
                  const { default: AutomationSetUp } = await import('./components/pages/automationSetUp/AutomationSetUp');
                  temp_hash[temp_mod_name] = <AutomationSetUp />;
                } else if (temp_mod_name === 'Tax') {
                  const { default: TaxSetup } = await import('./components/pages/taxSetup/TaxSetup');
                  temp_hash[temp_mod_name] = <TaxSetup />;
                } else if (temp_mod_name === 'AccountRateCode') {
                  const { default: AccountRateCodeSetup } = await import('./components/pages/accountRateCodeSetup/AccountRateCodeSetup');
                  temp_hash[temp_mod_name] = <AccountRateCodeSetup />;
                } else if (temp_mod_name === 'RateCode') {
                  const { default: RateCodeSetup } = await import('./components/pages/rateCodeSetup/RateCodeSetup');
                  temp_hash[temp_mod_name] = <RateCodeSetup />;
                } else if (temp_mod_name === 'Min-MaxSettings') {
                  const { default: MinimumRateMapping } = await import('./components/pages/minimumRateMapping/MinimumRateMapping');
                  temp_hash[temp_mod_name] = <MinimumRateMapping />;
                } else if (temp_mod_name === 'AccessSetup') {
                  const { default: RolesSetup } = await import('./components/pages/rolesSetup/RolesSetup');
                  temp_hash[temp_mod_name] = <RolesSetup />;
                } else if (temp_mod_name === 'Cars') {
                  const { default: Cars } = await import('./components/pages/cars/Cars');
                  temp_hash[temp_mod_name] = <Cars />;
                } else if (temp_mod_name === 'Reports') {
                  const { default: Reports } = await import('./components/pages/reports/Reports');
                  temp_hash[temp_mod_name] = <Reports />;
                } else if (temp_mod_name === 'EventLog') {
                  const { default: EventLog } = await import('./components/pages/eventLog/EventLog');
                  temp_hash[temp_mod_name] = <EventLog />;
                } else if (temp_mod_name === 'MyProfile') {
                  const { default: MyProfile } = await import('./components/pages/my_profile/MyProfile');
                  temp_hash[temp_mod_name] = <MyProfile />;
                } else if (temp_mod_name === 'EnableAutomation') {
                  const { default: EnableAutomation } = await import('./components/pages/enableAutomation/EnableAutomation');
                  temp_hash[temp_mod_name] = <EnableAutomation />;
                } else if (temp_mod_name === 'RateCodeMapping') {
                  const { default: RateCodeMapping } = await import('./components/pages/rateCodeMapping/RateCodeMapping');
                  temp_hash[temp_mod_name] = <RateCodeMapping />;
                } else if (temp_mod_name === 'CurrencyConverter') {
                  const { default: CurrencyConverter } = await import('./components/pages/currencyConverter/CurrencyConverter');
                  temp_hash[temp_mod_name] = <CurrencyConverter />;
                } else if (temp_mod_name === 'ReportSetUp') {
                  const { default: ReportSetUp } = await import('./components/pages/reportSetUp/ReportSetUp');
                  temp_hash[temp_mod_name] = <ReportSetUp />;
                } else if (temp_mod_name === 'RateDownload') {
                  const { default: RateDownload } = await import('./components/pages/rateDownload/RateDownload');
                  temp_hash[temp_mod_name] = <RateDownload />;
                } else if (temp_mod_name === 'MarketInsightLocation') {
                  const { default: MILocation } = await import('./components/pages/marketInsights/Location');
                  temp_hash[temp_mod_name] = <MILocation />;
                } else if (temp_mod_name === 'RateInsights') {
                  const { default: RateInsights } = await import('./components/pages/marketInsights/HotDates');
                  temp_hash[temp_mod_name] = <RateInsights />;
                } else if (temp_mod_name === 'CustomStrategy') {
                  const { default: CustomStrategy } = await import('./components/pages/strategy/Strategy');
                  temp_hash[temp_mod_name] = <CustomStrategy />;
                }
                
              });  
          }
          return temp_mod_names;
        })

        if (foundUser?.role === 'Users') {
          axios.post(baseURL + "/get_individual_user_details", { 'user_email': foundUser.userEmail })
            .then((res1) => {
              // console.log('foundUser',foundUser)
              let accnt = foundUser.account_id
              if (foundUser.account_type.account_type != 'Corporate') {
                accnt = foundUser.account_type.affiliated_to_id
              }
              axios.post(baseURL + "/get_user_role_detail", { 'user_role': res1.data[0].selected_role, 'account_id': accnt.toString() })
                .then((res2) => {
                  // console.log("get_user_role_detail-res",res2)
                  res2.data[0].role_definition['MyProfile_Access Specifier'] = "Edit";
                  const tempMenuItems = temp_module_arr.filter((val) => {
                    if ((val === 'SupportScreen') || (val === 'ViewBaseRates' && (res2.data[0].role_definition["RateUpdate_Access Specifier"] === "Edit"
                    || res2.data[0].role_definition["RateUpdate_Access Specifier"] === "View")) || (res2.data[0].role_definition[val + "_Access Specifier"] === "Edit"
                      || res2.data[0].role_definition[val + "_Access Specifier"] === "View")) {
                      return val
                    }
                  })
                  setModuleDetailHash(temp_hash);
                  setModuleDetail(tempMenuItems);
                })
            })
        }
        else if (foundUser?.role === 'SuperAdmin') {
          setModuleDetailHash(temp_hash);
          setModuleDetailHash2(temp_hash2);
          setModuleDetail(temp_module_arr);

        }
          // preloadComponents();
        }
        // let temp_hash = {};
        // let temp_hash2 = {};      
        // const temp_module_arr = res.data.map((val) => {
        //   let temp_mod_names = [];
        //   let subModuleExists = val.hasOwnProperty('sub_module');
        //   let sub_modules = subModuleExists ? val.sub_module : null;

        //   let temp_mod_name = val.name.toString().trim();
        //   if (temp_mod_name.indexOf(' ') > -1) {
        //       let t1_split = temp_mod_name.split(" ");
        //       if (t1_split.length > 0) {
        //           temp_mod_name = '';
        //           for (let i1 = 0; i1 < t1_split.length; i1++) {
        //               temp_mod_name += t1_split[i1];
        //           }
        //       }
        //   }
        //   if (sub_modules !== null && sub_modules.length > 0) {
        //     for (let i = 0; i < sub_modules.length; i++) {
        //       let sub_module = sub_modules[i].routing;
        //       if (sub_module.indexOf(' ') > -1) {
        //         let t1_split = sub_module.split(" ");
        //         if (t1_split.length > 0) {
        //             sub_module = '';
        //             for (let i1 = 0; i1 < t1_split.length; i1++) {
        //               sub_module += t1_split[i1];
        //             }
        //         }
        //       }
        //       temp_mod_names.push(sub_module.toString().trim());
        //     }
        //   }
        //   temp_mod_names.push(temp_mod_name);
        //   if (temp_mod_names.length > 1) {
        //     temp_mod_names.forEach((temp_mod_name, index) => {
        //       if (temp_mod_name === 'Min/MaxSettings') {
        //         temp_hash2[temp_mod_name] = components.MinimumRateMapping;
        //       } else if (temp_mod_name === 'fleet_setup') {
        //         temp_hash2[temp_mod_name] = components.FleetSetUp;
        //       } else if (temp_mod_name === 'fees_taxes') {
        //         temp_hash2[temp_mod_name] = components.TaxSetup;
        //       } else if (temp_mod_name === 'competitor_setup') {
        //         temp_hash2[temp_mod_name] = components.CompetitorSetup;
        //       } else if (temp_mod_name === 'Account') {
        //         temp_hash2[temp_mod_name] = components.AccountSetup;
        //       } else if (temp_mod_name === 'locations') {
        //         temp_hash2[temp_mod_name] = components.Locations;
        //       } else if (temp_mod_name === 'AccountLocations') {
        //         temp_hash2[temp_mod_name] = components.AccountLocations;
        //       } else if (temp_mod_name === 'competitors') {
        //         temp_hash2[temp_mod_name] = components.Competitors;
        //       } else if (temp_mod_name === 'fleet_standard') {
        //         temp_hash2[temp_mod_name] = components.FleetStandard;
        //       } else if (temp_mod_name === 'automation_plan') {
        //         temp_hash2[temp_mod_name] = components.AutomationPlans;
        //       } else if (temp_mod_name === 'user') {
        //         temp_hash2[temp_mod_name] = components.UserSetup;
        //       } else if (temp_mod_name === 'cars') {
        //         temp_hash2[temp_mod_name] = components.Cars;
        //       } else if (temp_mod_name === 'InstantAutomationPro') {
        //         temp_hash2[temp_mod_name] = components.InstantAutomationPro;
        //       } else if (temp_mod_name === 'InstantRateShop') {
        //         temp_hash2[temp_mod_name] = components.InstantRateShop;
        //       } else if (temp_mod_name === 'AutomationScheduler') {
        //         temp_hash2[temp_mod_name] = components.AutomationScheduler;
        //       } else if (temp_mod_name === 'report_setup') {
        //         temp_hash2[temp_mod_name] = components.ReportSetUp;
        //       } else if (temp_mod_name === 'rate_download') {
        //         temp_hash2[temp_mod_name] = components.RateDownload;
        //       }
        //     });
        //   } else {
        //       // temp_mod_names.forEach(temp_mod_name => { 
        //       temp_mod_names.forEach(async (temp_mod_name) => {  
        //         if (temp_mod_name === 'Account') {
        //           const { default: Account } = await import('./components/pages/account/Account');
        //           temp_hash[temp_mod_name] = <Account />;
        //         } else if (temp_mod_name === 'Min/MaxSettings') {
        //           const { default: MinimumRateMapping } = await import('./components/pages/minimumRateMapping/MinimumRateMapping');
        //           temp_hash[temp_mod_name] = <MinimumRateMapping />;
        //         } else if (temp_mod_name === 'FeesandTaxes') {
        //           const { default: TaxSetup } = await import('./components/pages/taxSetup/TaxSetup');
        //           temp_hash[temp_mod_name] = <TaxSetup />;
        //         } else if (temp_mod_name === 'CompetitorSetUp') {
        //           const { default: CompetitorSetup } = await import('./components/pages/competitorSetup/CompetitorSetup');
        //           temp_hash[temp_mod_name] = <CompetitorSetup />;
        //         } else if (temp_mod_name === 'AccountLocations') {
        //           const { default: AccountLocations } = await import('./components/pages/accountLocations/AccountLocations');
        //           temp_hash[temp_mod_name] = <AccountLocations />;
        //         } else if (temp_mod_name === 'Locations') {
        //           const { default: Locations } = await import('./components/pages/locations/Locations');
        //           temp_hash[temp_mod_name] = <Locations />;
        //         } else if (temp_mod_name === 'Competitors') {
        //           const { default: Competitors } = await import('./components/pages/competitors/Competitors');
        //           temp_hash[temp_mod_name] = <Competitors />;
        //         } else if (temp_mod_name === 'FleetStandard') {
        //           const { default: FleetStandard } = await import('./components/pages/fleetStandard/FleetStandard');
        //           temp_hash[temp_mod_name] = <FleetStandard />;
        //         } else if (temp_mod_name === 'FleetSetUp') {
        //           const { default: FleetSetUp } = await import('./components/pages/fleetSetUp/FleetSetUp');
        //           temp_hash[temp_mod_name] = <FleetSetUp />;
        //         } else if (temp_mod_name === 'AutomationPlan') {
        //           const { default: AutomationPlans } = await import('./components/pages/automationPlans/AutomationPlans');
        //           temp_hash[temp_mod_name] = <AutomationPlans />;
        //         } else if (temp_mod_name === 'User') {
        //           const { default: UserSetup } = await import('./components/pages/user/User');
        //           temp_hash[temp_mod_name] = <UserSetup />;
        //         } else if (temp_mod_name === 'InstantAutomationPro') { 
        //           const { default: InstantAutomationPro } = await import('./components/pages/instantAutomationPro/InstantAutomationPro');
        //           temp_hash[temp_mod_name] = <InstantAutomationPro />;
        //         } else if (temp_mod_name === 'InstantRateShop') {
        //           const { default: InstantRateShop } = await import('./components/pages/instantRateShop/instantRateShop');
        //           temp_hash[temp_mod_name] = <InstantRateShop />;
        //         } else if (temp_mod_name === 'AutomationScheduler') {
        //           const { default: AutomationSetUp } = await import('./components/pages/automationSetUp/AutomationSetUp');
        //           temp_hash[temp_mod_name] = <AutomationSetUp />;
        //         } else if (temp_mod_name === 'Tax') {
        //           const { default: TaxSetup } = await import('./components/pages/taxSetup/TaxSetup');
        //           temp_hash[temp_mod_name] = <TaxSetup />;
        //         } else if (temp_mod_name === 'AccountRateCode') {
        //           const { default: AccountRateCodeSetup } = await import('./components/pages/accountRateCodeSetup/AccountRateCodeSetup');
        //           temp_hash[temp_mod_name] = <AccountRateCodeSetup />;
        //         } else if (temp_mod_name === 'RateCode') {
        //           const { default: RateCodeSetup } = await import('./components/pages/rateCodeSetup/RateCodeSetup');
        //           temp_hash[temp_mod_name] = <RateCodeSetup />;
        //         } else if (temp_mod_name === 'Min-MaxSettings') {
        //           const { default: MinimumRateMapping } = await import('./components/pages/minimumRateMapping/MinimumRateMapping');
        //           temp_hash[temp_mod_name] = <MinimumRateMapping />;
        //         } else if (temp_mod_name === 'AccessSetup') {
        //           const { default: RolesSetup } = await import('./components/pages/rolesSetup/RolesSetup');
        //           temp_hash[temp_mod_name] = <RolesSetup />;
        //         } else if (temp_mod_name === 'Cars') {
        //           const { default: Cars } = await import('./components/pages/cars/Cars');
        //           temp_hash[temp_mod_name] = <Cars />;
        //         } else if (temp_mod_name === 'Reports') {
        //           const { default: Reports } = await import('./components/pages/reports/Reports');
        //           temp_hash[temp_mod_name] = <Reports />;
        //         } else if (temp_mod_name === 'EventLog') {
        //           const { default: EventLog } = await import('./components/pages/eventLog/EventLog');
        //           temp_hash[temp_mod_name] = <EventLog />;
        //         } else if (temp_mod_name === 'MyProfile') {
        //           const { default: MyProfile } = await import('./components/pages/my_profile/MyProfile');
        //           temp_hash[temp_mod_name] = <MyProfile />;
        //         } else if (temp_mod_name === 'EnableAutomation') {
        //           const { default: EnableAutomation } = await import('./components/pages/enableAutomation/EnableAutomation');
        //           temp_hash[temp_mod_name] = <EnableAutomation />;
        //         } else if (temp_mod_name === 'RateCodeMapping') {
        //           const { default: RateCodeMapping } = await import('./components/pages/rateCodeMapping/RateCodeMapping');
        //           temp_hash[temp_mod_name] = <RateCodeMapping />;
        //         } else if (temp_mod_name === 'CurrencyConverter') {
        //           const { default: CurrencyConverter } = await import('./components/pages/currencyConverter/CurrencyConverter');
        //           temp_hash[temp_mod_name] = <CurrencyConverter />;
        //         } else if (temp_mod_name === 'ReportSetUp') {
        //           const { default: ReportSetUp } = await import('./components/pages/reportSetUp/ReportSetUp');
        //           temp_hash[temp_mod_name] = <ReportSetUp />;
        //         } else if (temp_mod_name === 'RateDownload') {
        //           const { default: RateDownload } = await import('./components/pages/rateDownload/RateDownload');
        //           temp_hash[temp_mod_name] = <RateDownload />;
        //         } else if (temp_mod_name === 'MarketInsightLocation') {
        //           const { default: MILocation } = await import('./components/pages/marketInsights/Location');
        //           temp_hash[temp_mod_name] = <MILocation />;
        //         } else if (temp_mod_name === 'RateInsights') {
        //           const { default: RateInsights } = await import('./components/pages/marketInsights/HotDates');
        //           temp_hash[temp_mod_name] = <RateInsights />;
        //         } else if (temp_mod_name === 'CustomStrategy') {
        //           const { default: CustomStrategy } = await import('./components/pages/strategy/Strategy');
        //           temp_hash[temp_mod_name] = <CustomStrategy />;
        //         }
                
        //       });  
        //   }
        //   return temp_mod_names;
        // })

        // if (foundUser?.role === 'Users') {
        //   axios.post(baseURL + "/get_individual_user_details", { 'user_email': foundUser.userEmail })
        //     .then((res1) => {
        //       // console.log('foundUser',foundUser)
        //       let accnt = foundUser.account_id
        //       if (foundUser.account_type.account_type != 'Corporate') {
        //         accnt = foundUser.account_type.affiliated_to_id
        //       }
        //       axios.post(baseURL + "/get_user_role_detail", { 'user_role': res1.data[0].selected_role, 'account_id': accnt.toString() })
        //         .then((res2) => {
        //           // console.log("get_user_role_detail-res",res2)
        //           res2.data[0].role_definition['MyProfile_Access Specifier'] = "Edit";
        //           const tempMenuItems = temp_module_arr.filter((val) => {
        //             if ((val === 'SupportScreen') || (val === 'ViewBaseRates' && (res2.data[0].role_definition["RateUpdate_Access Specifier"] === "Edit"
        //             || res2.data[0].role_definition["RateUpdate_Access Specifier"] === "View")) || (res2.data[0].role_definition[val + "_Access Specifier"] === "Edit"
        //               || res2.data[0].role_definition[val + "_Access Specifier"] === "View")) {
        //               return val
        //             }
        //           })
        //           setModuleDetailHash(temp_hash);
        //           setModuleDetail(tempMenuItems);
        //         })
        //     })
        // }
        // else if (foundUser?.role === 'SuperAdmin') {
        //   setModuleDetailHash(temp_hash);
        //   setModuleDetailHash2(temp_hash2);
        //   setModuleDetail(temp_module_arr);

        // }
      })
      // console.log('foundUser',foundUser)
  }
  console.log('im at outtttttt')

  }, [])

  return (
    <>
      <Router>
      <Suspense fallback = {<div className="loader-overlay2">
                              <div className="spinner2"></div>
                            </div>}>

        {!loginSuccess ? "" : (<>
          {collaspedFlag ? <NavibarCollasped setLoginSuccess={setLoginSuccess} /> : <Navibar setSidebarColor={setSidebarColor} sidebarColor={sidebarColor} setLoginSuccess={setLoginSuccess} />}

          <div className="customSidebar">
            <Sidebar setCollapsedFlag={setCollapsedFlag} sidebarColor={sidebarColor} />
          </div>
        </>)}
        <div className={!loginSuccess ? "" : collaspedFlag ? 'rightSideContentCollasped' : "rightSideContent"}>
        <Routes>
            {!loginSuccess ?
              <>
              <Route exact path="/login" element={<Login setLoginSuccess={setLoginSuccess} />} />
              <Route exact path="/" element={<Website />} />
              <Route exact path="/contact-us" element={<ContactUs />} />
              </>

              :
              (<>
                <Route exact path="/dashboard/" element={<Dashboard />} />
                {/* {moduleDetailHash} */}
                
                {
                  
                  moduleDetail.length > 0 ? moduleDetail.map((val) => (<>
                    { val.length > 1 ? val.map((val2) => (
                     
                      (<><Route exact path={"/"+val2} element={moduleDetailHash2[val2]} /></>)
                      
                    ))
                    :
                    // {val}
                    (<Route exact path={"/"+ val} element={moduleDetailHash[val]} />)
                    }
                    </>
                  )) : ""
                }

              </>)}
          </Routes>

          {/* <Routes>
            {!loginSuccess ?
              <>
              <Route exact path="/login" element={<Login setLoginSuccess={setLoginSuccess} />} />
              <Route exact path="/" element={<Website />} />
              <Route exact path="/contact-us" element={<ContactUs />} />
              </>

              :
              (<>
                <Route exact path="/" element={<Dashboard />} />
                {
                  moduleDetail.length > 0 ? moduleDetail.map((val) => (<>
                    { val.length > 1 ? val.map((val2) => (
                      (<><Route exact path={"/"+val2} element={moduleDetailHash2[val2]} /></>)
                    ))
                    :
                    (<Route exact path={"/" + val} element={moduleDetailHash[val]} />)
                    }
                    </>
                  )) : ""
                }

              </>)}
          </Routes> */}
        </div>
        {/* {(sessionStorage.getItem("user_token") )
                ?
                (<>
                <Dashboard/>
                <AccountSetup />
                <MinimumRateMapping />
                <TaxSetup />
                <CompetitorSetup />
                <AccountLocations /> 
                <AccountSetup /> 
                <Locations/>  
                <Competitors /> 
                <FleetStandard />
                <FleetSetUp />
                <AutomationPlans />
                <UserSetup /> 
                <InstantAutomationPro />  
                <RolesSetup />
                <Cars />
                <Reports />    
                <EventLog />
                <MyProfile />
                <CompetitorSetup />
                <EnableAutomation />
                <AutomationPlans />  
                <RateCodeMapping />
                <CurrencyConverter />
                <ReportSetUp />
                <RateDownload /> 
                <MILocation /> 
                <MIHotDates />
                <CustomStrategy />
                </>)
              : ''} */}
          

        </Suspense>
      </Router>
    </>
  );
}

export default App;
