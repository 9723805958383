import React, { useState, useEffect } from 'react'
import './website.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';

import image1 from './Data Viz new.png';
import image2 from './Intergrations Chip new.png';
import image3 from './AdobeStock_527382497new.png';
import image4 from './AdobeStock_597475061 new.png';
import image5 from './AdobeStock_534165792 new.png';
import image6 from './RunaPlay new.png';
import integration from './Integrations Chip_blue.png';
import customization from './output-onlinepngtools (2).png';
import reporting from './Reporting_blue.png';
import datawiz from './datawiz_blue.png';
import runaplay from './runaplay_blue.png';
import competitor_analysis from './competitor_analysis_blue.png';

const Website = () => {
  const redirectToLogin = () => {
    const currentURL = window.location.origin; // Get the base URL (protocol + host)
    window.location.href = `${currentURL}/login`; 

    // Logic for redirecting to login
  };


  const scrollToSection = (id) => {
    const section = document.getElementById(id);
    if (section) {
      section.scrollIntoView({ behavior: 'smooth' });
    }
  };

  const handleRedirect = () => {
    window.location.href = '/contact-us'; // Redirect to the contact-us page
  };

  

//   const [isExpanded, setIsExpanded] = useState(false);

//   const toggleExpand = () => {
//     setIsExpanded(!isExpanded);
//   };

  const [expandedIndex, setExpandedIndex] = React.useState(null);

const toggleExpand = (index) => {
  setExpandedIndex(expandedIndex === index ? null : index);
};

    const [selectedBox, setSelectedBox] = useState(null); // Track which box is selected
    const handleBoxClick = (index) => {
        setSelectedBox(index);
      };
      
    
      const boxStyle = (index) => {
        return {
          backgroundColor: selectedBox === index ? 'black' : 'white',
          color: selectedBox === index ? 'white' : 'black',
          cursor: 'pointer', // Optional: to indicate the box is clickable
          transition: 'background-color 0.3s ease, color 0.3s ease', // Smooth transition for color change
        };
      };
    // const handleBoxClick = (index) => {
    //     setSelectedBox(index); // Set selected box based on its index
    //   };
    //   const handleBoxClick = (index) => {
    //     // Toggle the selected box
    //     setSelectedBox(selectedBox === index ? null : index);
    //   };
  
    

  return (
    <div className='Master1'>
        <div className="header1">
            <h1 className="rateMatePro1">RateMatePro</h1>
            <div className="nav-buttons1">
            <button className="features-link1" onClick={() => scrollToSection('features')}>Features</button>
            <button className="faqs-link1" onClick={() => scrollToSection('faqs')}>FAQs</button>
            <button className="faqs-link1" onClick={handleRedirect}>Contact Us</button>
            <button onClick={redirectToLogin}>Login</button>
            </div>
        </div>

        <div className="content-wrapper1">
            <div className="unlock-tomorrow1">
            <div>Unlock Tomorrow:</div>
            <div>
                <span className="faster1" style={{color:'#6DACE7'}}>Faster</span> Solutions,
            </div>
            <div>
                <span className="smarter1" style={{color:'#6DACE7'}}>Smarter</span> Decisions.
            </div>
            </div>
            
            {/* <div className="unlock-tomorrow">Unlock Tomorrow: Faster Solutions, Smarter Decisions.</div> */}
            <div className="game-changer1" style={{marginTop:'6%'}}>A game-changer for rental car companies of all sizes</div>
           

            {/* <div className="data-driven-pricing1" style={{marginTop:'3%', marginLeft:'5%'}}>Unlock the <span style={{color:'#6DACE7'}}>Power</span> of Data-Driven Pricing ! </div> */}
            {/* <div className="data-driven-pricing1" style={{ marginTop: '3%', marginLeft: '5%' }}>
  Unlock the <span style={{ color: '#6DACE7' }}>Power</span> of 
  <span style={{ position: 'relative', display: 'inline-block' }}>
    Data-Driven
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="368"
      height="16"
      viewBox="0 0 368 16"
      style={{
        position: 'absolute',
        bottom: '-5px', 
        left: '0',
      }}
    >
      <path
        d="M0 8 C92 16, 276 16, 368 8" 
        stroke="#AE8FF7"
        strokeWidth="6"
        fill="none"
      />
    </svg>
  </span>
  Pricing!
</div> */}

<div
  className="data-driven-pricing1"
  style={{
    marginTop: '3%',
    marginLeft: '5%',
    textAlign: 'center',
  }}
>
  Unlock the <span style={{ color: '#6DACE7' }}>Power</span> of 
  <span style={{ display: 'inline-block', marginLeft: '-5px' }}>
    Data-Driven Pricing!
    <svg
      xmlns="http://www.w3.org/2000/svg"
      height="32"  // Increased height for a bigger curve
      viewBox="0 0 1200 32"  // Increased width for a bigger curve space
      style={{
        width: '80%',  // Adjust width as needed
        opacity: '1',
        transform: 'translateX(-7%)',
        marginTop:'-68px'
      }}
    >
      <path
        d="M0 0 C250 34, 600 32, 800 0"  // Adjusted coordinates for a bigger curve
        stroke="#AE8FF7"
        strokeWidth="10"  // Increased stroke width for a thicker curve
        fill="none"
      />
    </svg>
  </span>
</div>









            <div className="market-competitive-content1" style={{marginTop:'4%'}}>
                In today's competitive rental car market, staying ahead requires more than just a hunch. It requires data-driven decisions and automation. Maximize revenue, increase profitability, and free up your team's time to focus on what matters most – delivering an exceptional rental experience to your customers.
                <br /><br />
                Don't let your competitors leave you in the dust! Embrace the future of rental car rate management with RateMatePro.
            </div>
        </div>
        <div className="features-section1" id="features" style={{marginTop:'7%'}}>
           Features
        </div>
        {/* #6DACE7 */}

        <div className="real-time-section1">
           <span>Real-Time Insights, </span>
           <span className="real-time-results1" style={{color:'#AA7AEB'}}>Real-Time Results</span>
        </div>
        <div className="stop-spending-section1">
            Stop spending countless hours shopping and manually adjusting your rental car rates. 
            Keep to date with real time rate data and instant rate updates.
        </div>
        <div className="box-section1">
            <div className="row1">
                <div className="box1" onClick={() => handleBoxClick(0)} style={boxStyle(0)}>
                    <img
                    style={{
                    width: '151px',
                    height: '122px',
                    objectFit: 'contain',
                    marginTop: '25px',
                    backgroundColor: selectedBox === 0 ? 'black' : '', // Make the selected box image visible
                    }}
                    src={datawiz} alt="Data Visualization" className="box-image1" />
                    <span
                        style={{
                        width:'239px',
                        height:'24px',
                        fontFamily: 'Manrope',
                        fontSize: '30px',
                        fontWeight: '800',
                        lineHeight: '28.23px',
                        letterSpacing: '-0.055em',
                        textAlign: 'center',
                        textDecorationSkipInk: 'none',
                        }}
                    >
                        Data Visualization
                    </span>
                    <p
    style={{
        width: '301px',
        height: 'auto', // Adjust height automatically to content
        transform: 'translateY(-32px)',
        fontFamily: 'Manrope',
        fontSize: '20px',
        fontWeight: '500',
        lineHeight: '26.32px',
        textAlign: 'center',
    }}
    className="box-content1"
>
    View complex data in a visually<br />
    understandable way through<br />
    charts and graphs
</p>
                    {/* <p
                        style={{
                        width:'301px',
                        height:'71px',
                        transform: 'translateY(-32px)',
                        fontFamily: 'Manrope',
                        fontSize: '20px',
                        fontWeight: '500',
                        lineHeight: '26.32px',
                        textAlign: 'center',
                        }}
                        className="box-content1"
                >
                    View complex data in a visually understandable way through charts and graphs
                </p> */}
                </div>
                <div className="box1" onClick={() => handleBoxClick(1)} style={boxStyle(1)}>
                    <img 
                    style={{
                        width: '135px',
                        height: '136px',
                        objectFit: 'contain',
                        marginTop: '25px',
                        backgroundColor: selectedBox === 1 ? 'black' : '', // Make the selected box image visible
                    }}
                    src={integration} alt="Integrations" className="box-image1" />
                    <span
                        style={{
                        width:'151px',
                        height:'24px',
                        fontFamily: 'Manrope',
                        fontSize: '30px',
                        fontWeight: '800',
                        lineHeight: '28.23px',
                        letterSpacing: '-0.055em',
                        textAlign: 'center',
                        transform: 'translateY(-21px)',
                        textDecorationSkipInk: 'none',
                        }}
                    >
                        Integration
                    </span>
                    <p
                        style={{
                        width:'301px',
                        height:'71px',
                        transform: 'translateY(-59px)',
                        fontFamily: 'Manrope',
                        fontSize: '20px',
                        fontWeight: '500',
                        lineHeight: '26.32px',
                        textAlign: 'center',
                        }}
                        className="box-content1"
                >
                        Seamless integration with other software and instant rate updates
                    </p>
                </div>
                <div className="box1" onClick={() => handleBoxClick(2)} style={boxStyle(2)}>
                    <img 
                    style={{
                        width: '118px',
                        height: '102px',
                        objectFit: 'contain',
                        marginTop: '44px',
                        backgroundColor: selectedBox === 2 ? 'black' : '', // Make the selected box image visible
                    }}
                    src={customization} alt="Custom Image 1" className="box-image1" />
                    <span
                        style={{
                        width:'199px',
                        height:'24px',
                        fontFamily: 'Manrope',
                        fontSize: '30px',
                        fontWeight: '800',
                        lineHeight: '28.23px',
                        letterSpacing: '-0.055em',
                        textAlign: 'center',
                        transform: 'translateY(0px)',
                        textDecorationSkipInk: 'none',
                        }}
                    >
                        Customization
                    </span>
                    {/* <p
                        style={{
                        width:'301px',
                        height:'71px',
                        transform: 'translateY(-59px)',
                        fontFamily: 'Manrope',
                        fontSize: '20px',
                        fontWeight: '500',
                        lineHeight: '26.32px',
                        textAlign: 'center',
                        }}
                        className="box-content1"
                >
                        Allowing users to tailor the dashboard to their specific needs and preferences.
                    </p> */}
                    <p
    style={{
        width: '301px',
        height: 'auto', // Adjust height dynamically
        transform: 'translateY(-33px)',
        fontFamily: 'Manrope',
        fontSize: '20px',
        fontWeight: '500',
        lineHeight: '26.32px',
        textAlign: 'center',
    }}
    className="box-content1"
>
    Allowing users to tailor the<br />
    dashboard to their specific needs<br />
    and preferences.
</p>
                </div>
            </div>
            <div className="row1">
                <div className="box1" onClick={() => handleBoxClick(3)} style={boxStyle(3)}>
                    <img 
                    style={{
                        width: '125px',
                        height: '129px',
                        objectFit: 'contain',
                        marginTop: '25px',
                        backgroundColor: selectedBox === 3 ? 'black' : '', // Make the selected box image visible
                    }}
                    src={reporting} alt="Custom Image 2" className="box-image1" />
                    <span
                        style={{
                        width:'135px',
                        height:'24px',
                        fontFamily: 'Manrope',
                        fontSize: '30px',
                        fontWeight: '800',
                        lineHeight: '28.23px',
                        letterSpacing: '-0.055em',
                        textAlign: 'center',
                        textDecorationSkipInk: 'none',
                        }}
                    >
                        Reporting
                    </span>
                    {/* <p
                        style={{
                        width:'301px',
                        height:'72px',
                        transform: 'translateY(-32px)',
                        fontFamily: 'Manrope',
                        fontSize: '20px',
                        fontWeight: '500',
                        lineHeight: '26.32px',
                        textAlign: 'center',
                        }}
                        className="box-content1"
                >
                        Generating detailed reports that provide insights into the business's performance.
                    </p> */}
                    <p
    style={{
        width: '301px',
        height: 'auto', // Automatically adjusts height
        transform: 'translateY(-32px)',
        fontFamily: 'Manrope',
        fontSize: '20px',
        fontWeight: '500',
        lineHeight: '26.32px',
        textAlign: 'center',
    }}
    className="box-content1"
>
    Generating detailed reports that<br />
    provide insights into the<br />
    business's performance.
</p>
                </div>
                <div className="box1" onClick={() => handleBoxClick(4)} style={boxStyle(4)}>
                    <img 
                    style={{
                        width: '149px',
                        height: '135px',
                        objectFit: 'contain',
                        marginTop: '25px',
                        backgroundColor: selectedBox === 4 ? 'black' : '', // Make the selected box image visible
                    }}
                    src={competitor_analysis} alt="Custom Image 3" className="box-image1" />
                    <span
                        style={{
                        width:'273px',
                        height:'24px',
                        fontFamily: 'Manrope',
                        fontSize: '30px',
                        fontWeight: '800',
                        lineHeight: '28.23px',
                        letterSpacing: '-0.055em',
                        textAlign: 'center',
                        textDecorationSkipInk: 'none',
                        }}
                    >
                        Competitor Analysis
                    </span>
                    {/* <p
                        style={{
                        width:'301px',
                        height:'72px',
                        transform: 'translateY(-32px)',
                        fontFamily: 'Manrope',
                        fontSize: '20px',
                        fontWeight: '500',
                        lineHeight: '26.32px',
                        textAlign: 'center',
                        }}
                        className="box-content1"
                >
                        Collect real-time data on pricing trends, discounts, and promotions to keep you in the loop
                    </p> */}
                    <p
    style={{
        width: '301px',
        height: 'auto', // Automatically adjusts height
        transform: 'translateY(-32px)',
        fontFamily: 'Manrope',
        fontSize: '20px',
        fontWeight: '500',
        lineHeight: '26.32px',
        textAlign: 'center',
    }}
    className="box-content1"
>
    Collect real-time data on pricing<br />
    trends, discounts, and promotions<br />
    to keep you in the loop
</p>

                </div>
                <div className="box1" onClick={() => handleBoxClick(5)} style={boxStyle(5)}>
                    <img 
                    style={{
                        width: '176px',
                        height: '158px',
                        objectFit: 'contain',
                        marginTop: '25px',
                        backgroundColor: selectedBox === 5 ? 'black !important' : '', // Make the selected box image visible
                    }}
                    src={runaplay} alt="Runa Play" className="box-image1" />
                    <span
                        style={{
                        width:'253px',
                        height:'24px',
                        fontFamily: 'Manrope',
                        fontSize: '30px',
                        fontWeight: '800',
                        lineHeight: '28.23px',
                        letterSpacing: '-0.055em',
                        textAlign: 'center',
                        textDecorationSkipInk: 'none',
                        }}
                    >
                        Custom Strategies
                    </span>
                    {/* <p
                        style={{
                        width:'301px',
                        height:'99px',
                        transform: 'translateY(-32px)',
                        fontFamily: 'Manrope',
                        fontSize: '20px',
                        fontWeight: '500',
                        lineHeight: '26.32px',
                        textAlign: 'center',
                        }}
                        className="box-content1"
                >
                        You're in control. Set your pricing strategy based on your business goals, seasonality, or other key factors
                    </p> */}
                    <p
    style={{
        width: '301px',
        height: 'auto', // Adjusts height dynamically
        transform: 'translateY(-7px)',
        fontFamily: 'Manrope',
        fontSize: '20px',
        fontWeight: '500',
        lineHeight: '26.32px',
        textAlign: 'center',
    }}
    className="box-content1"
>
    You're in control. Set your pricing<br />
    strategy based on your business<br />
    goals, seasonality, or other key<br />
    factors.
</p>

                </div>
            </div>
        </div>
        
        <div
            className="get-started1"
            onClick={handleRedirect}
            style={{
            width: '218px',
            height: '61px',
            padding: '25px 40px',
            gap: '10px',
            borderRadius: '47px 47px 47px 47px',
            background: '#212227',
            // opacity: '0', // Set to 1 to make it visible
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            margin: '20px auto', // Center the button horizontally
            color: '#AA7AEB',
            fontFamily: 'Manrope',
            fontSize: '20px',
            fontWeight: '600',
            cursor: 'pointer',
            }}
        >
          Get Started &nbsp; <FontAwesomeIcon icon={faArrowRight} />
      </div>
      <div
        className="faq-section1"
        style={{
            width: '216px',
            height: '33px',
            padding: '12px 11px',
            gap: '10px',
            borderRadius: '47px 47px 47px 47px',
            background: '#D3D3D3',
            border: '1px solid',
            borderImageSource: 'linear-gradient(99.94deg, #A09F9F 42.55%, rgba(0, 0, 0, 0) 92.4%)',
            opacity: '1', // Adjust this to make it visible (set to 1 for full visibility)
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            margin: '20px auto',
            color: 'black',
            fontFamily: 'Manrope',
            fontSize: '16px',
            fontWeight: '600',
            cursor: 'pointer',
        }}
        >
        Frequently asked questions
    </div>

    <div
        className="faqs-title1" id = 'faqs'
        style={{
            width: '221px',
            height: '53px',
            gap: '0px',
            opacity: '1', // Adjust this to make it visible (set to 1 for full visibility)
            fontFamily: 'Manrope',
            fontSize: '70px',
            fontWeight: '800',
            lineHeight: '65.87px',
            letterSpacing: '-0.055em',
            textAlign: 'center',
            // textDecoration: 'underline', // To mimic the underlining effect
            textUnderlinePosition: 'from-font',
            textDecorationSkipInk: 'none',
            // background: '#000000',
            color: 'black',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            margin: '20px auto',
            borderRadius: '5px', // Optional for slightly rounded corners
        }}
        >
        FAQs
    </div>
    <div
  className="rectangle-box1"
  style={{
    width: '1140px',
    height: 'auto', // Set height to auto to accommodate dynamic content
    borderRadius: '40px 40px 40px 40px',
    opacity: '1',
    background: '#212227',
    margin: '20px auto',
    padding: '20px 0', // Add padding for better spacing
  }}
>
  {/* Map through an array of questions */}
  {[
    {
      question: 'What is RateMatePro?',
      answer:
        'RateMatePro is a smart rate management platform for car rental businesses. It helps optimize your pricing strategy by leveraging real-time market data and automation, ensuring you stay competitive and maximize revenue.',
    },
    {
      question: 'How can it benefit my business?',
      answer: (
        <>
          <p>• Maximize Revenue: Dynamic pricing adapts to market trends and demand.</p>
          <p>• Save Time: Automate updates and focus on growing your business.</p>
          <p>• Simplify Management: Centrally manage rates for multiple locations.</p>
          <p>• Stay Competitive: React quickly to market and competitor changes.</p>
        </>
      ),
    },
    
    
    {
      question: 'Do you offer rates for Airport Locations and City Locations?',
      answer:
        'Yes! RateMatePro supports rate optimization for both airport and city locations. Our system ensures that each location’s pricing is tailored to its unique demand patterns, customer profiles, and market conditions.',
    },
    {
      question: 'How do I get started using RateMatePro?',
      answer: (
        <>
          <p>Getting started is simple:</p>
          <p>• Contact Our Team: Schedule a quick consultation to discuss your needs.</p>
          <p>• Setup & Integration: We’ll guide you through a seamless onboarding process.</p>
          <p>• Start Optimizing: Use the intuitive dashboard to set rates and monitor performance.</p>
        </>
      ),
    },
    {
      question: 'What kind of customer support do you offer?',
      answer: (
        <>
          <p>We offer comprehensive customer support to ensure your success:</p>
          <p>• 24/7 Helpdesk: Access assistance anytime.</p>
          <p>• Onboarding Support: Personalized training during setup.</p>
          <p>• Dedicated Account Manager: Ongoing guidance tailored to your business.</p>
          <p>• Resource Hub: Guides, FAQs, and tutorials to help you make the most of RateMatePro.</p>
        </>
      ),
    }
    
    
    
  ].map((faq, index) => (
    <div key={index}>
      <div
        className="faq-content1"
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          marginBottom: '20px',
        }}
      >
        <p
          style={{
            marginTop:'5px',
            width: '952px',
            fontFamily: 'Manrope',
            fontSize: '30px',
            fontWeight: '500',
            lineHeight: '35.43px',
            letterSpacing: '-0.035em',
            textAlign: 'left',
            color: 'white',
            marginLeft: '95px',
          }}
        >
          {faq.question}
        </p>
        <button
  onClick={() => toggleExpand(index)}
  style={{
    width: '50px',
    height: '50px',
    background: '#FFFFFF',
    border: 'none',
    borderRadius: '50%',
    cursor: 'pointer',
    display: 'flex', // Flexbox for centering
    justifyContent: 'center', // Center horizontally
    alignItems: 'center', // Center vertically
    fontSize: '30px',
    fontWeight: 'bold',
    color: '#000000',
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
    transform: 'translateX(-92px)', // Horizontal position adjustment
    textAlign: 'center', // Ensure text is centered
    lineHeight: '50px', // Ensure vertical alignment of the icon
  }}
>
  {expandedIndex === index ? '-' : '+'}
</button>



      </div>
      {expandedIndex === index && (
        <div
          className="faq-answer1"
          style={{
            marginTop: '-10px',
            fontFamily: 'Manrope',
            fontSize: '20px',
            marginLeft: '100px',
            fontWeight: '400',
            lineHeight: '30px',
            letterSpacing: '-0.025em',
            textAlign: 'left',
            color: 'white',
            width: '850px',
          }}
        >
          <p>{faq.answer}</p>
        </div>
      )}
      {/* Add a separator line except for the last question */}
      {index < 5 && (
        <div
          style={{
            width: '90%',
            height: '1px',
            borderTop: '1px dotted #555555',
            margin: '20px auto',
          }}
        />
      )}
    </div>
  ))}

<div
            style={{
              marginTop: '3%',
              marginLeft: '9%',
              fontSize: '18px',
              fontFamily: 'Manrope',
              color:'white',
              lineHeight: '1.8', // Adjust for better spacing
            }}
          >
            Still have questions?{' '}
            <a
              href="/contact-us"
              style={{
                fontSize: '23px',
                fontWeight: '500',
                lineHeight: '32.93px',
                letterSpacing: '-0.055em',
                color: '#6DACE7',
                textDecoration: 'none',
              }}
            >
              Contact Us
            </a>{' '}
            for more details!
          </div>
</div>

    {/* <div
        className="rectangle-box"
        style={{
            width: '1140px',
            height: '645px',
            borderRadius: '40px 40px 40px 40px',
            opacity: '1',
            background: '#212227',
            margin: '20px auto', 
            display: 'block',
        }}
        >
        <div
        className="faq-content"
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <p
          style={{
            width: '952px',
            fontFamily: 'Manrope',
            fontSize: '30px',
            fontWeight: '500',
            lineHeight: '35.43px',
            letterSpacing: '-0.035em',
            textAlign: 'left',
            color:'white',
            marginLeft:'95px',
            marginTop:'55px'
          }}
        >
          What is RateMatePro, and how can it benefit my business?
        </p>
        <button
          onClick={toggleExpand}
          style={{
            width: '50px', // Increase width
            height: '50px', // Increase height
            background: '#FFFFFF', // Button background color
            border: 'none', // Remove border
            borderRadius: '50%', // Make it circular
            cursor: 'pointer', // Show pointer on hover
            display: 'flex', // Center the content
            justifyContent: 'center',
            alignItems: 'center',
            transform: 'translateX(-92px)',
            fontSize: '30px', // Increase font size for the '+'
            fontWeight: 'bold', // Make the '+' bold
            color: '#000000', // Color of the '+'
            boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)', //
          }}
        >
          {isExpanded ? '-' : '+'}
        </button>
      </div>
      {isExpanded && (  
        <div
          className="faq-answer"
          style={{
            marginTop: '-10px',
            fontFamily: 'Manrope',
            fontSize: '20px',
            marginLeft:'100px',
            fontWeight: '400',
            lineHeight: '30px',
            letterSpacing: '-0.025em',
            textAlign: 'left',
            color: 'white'
          }}
        >
          <p>
            RateMatePro is a comprehensive tool designed to help businesses analyze, monitor, and adjust their pricing strategies. It provides real-time insights, competitor comparisons, and automated suggestions to enhance your business profitability and competitiveness.
          </p>
        </div>
      )}
    </div> */}

      
        {/* <div className="any-questions" 
          style={{marginTop:'3%', marginLeft:'13%', fontSize:'18px'}}>Still have questions?  <span style={{color:'#6DACE7'}}>Contact Us</span> for more deatails!
         </div> */}

          {/* <div
            style={{
              marginTop: '3%',
              marginLeft: '9%',
              fontSize: '18px',
              fontFamily: 'Manrope',
              lineHeight: '1.8', // Adjust for better spacing
            }}
          >
            Still have questions?{' '}
            <a
              href="/RateMatePro/contact-us"
              style={{
                fontSize: '23px',
                fontWeight: '500',
                lineHeight: '32.93px',
                letterSpacing: '-0.055em',
                color: '#6DACE7',
                textDecoration: 'none',
              }}
            >
              Contact Us
            </a>{' '}
            for more details!
          </div>

 */}






<footer
  style={{
    backgroundColor: '#212227',
    color: 'white',
    padding: '20px 0',
    height: '300px',
    textAlign: 'left',
    marginTop: '40px',
    fontFamily: 'Manrope',
    fontSize: '14px',
    position: 'relative',
  }}
>
  {/* Left Section */}
  <div style={{ position: 'absolute', left: '140px', top: '20px' }}>
    {/* Styled "RateMatePro" */}
    <div
      style={{
        width: '211px',
        height: '24px',
        gap: '0px',
        marginTop:'40px',
        opacity: '1',
        fontFamily: 'Manrope',
        fontSize: '35px',
        fontWeight: '800',
        lineHeight: '32.93px',
        letterSpacing: '-0.055em',
        textAlign: 'left',
        textUnderlinePosition: 'from-font',
        textDecorationSkipInk: 'none',
        color: 'white',
        padding: '10px',
        borderRadius: '5px',
      }}
    >
      RateMatePro
    </div>

    {/* Description Below RateMatePro */}
    <div
      style={{
        width: '335px',
        height: '69px',
        gap: '0px',
        // marginTop:'15px',
        opacity: '1',
        fontFamily: 'Manrope',
        fontSize: '23px',
        fontWeight: '500',
        lineHeight: '32.93px',
        letterSpacing: '-0.055em',
        textAlign: 'left',
        textUnderlinePosition: 'from-font',
        textDecorationSkipInk: 'none',
        color: '#AFAFAF',
        padding: '10px',
        borderRadius: '5px',
        marginTop: '35px',
      }}
    >
      Stay ahead of the competition. Embrace the future of car rental rate management with RateMatePro.
    </div>
  </div>

  {/* Right Section: Contact Us */}
  {/* <div
    style={{
      position: 'absolute',
      right: '140px',
      top: '60px',
    }}
  >
    <a
      href="/RateMatePro/contact-us"
      style={{
        fontFamily: 'Manrope',
        fontSize: '23px',
        fontWeight: '500',
        lineHeight: '32.93px',
        letterSpacing: '-0.055em',
        color: '#6DACE7',
        // marginTop:'40px',
        textDecoration: 'none',
      }}
    >
      Contact Us
    </a>
  </div> */}
</footer>




    </div>
  );
};

export default Website;
